/*global require */
/**
 * Main app js loader
 * With requirejs optimizer, this will include the following libraries:
 * require, modernizr and jquery
 */
(function (window) {
	'use strict';

	// Make sure that we only run this application once
	var Config = window.Config || {};
	if (Config.yellowSubmarine) { // if this app was already loaded, don't do it again
		return;
	}

	Config.yellowSubmarine = true;
	window.Config = Config;

	require(
		[
			'config'
		],
		function () {

			require(
				[
					'core/setupApp'
				]
			);
		}
	);
})(window);

define("mainFull", function(){});

