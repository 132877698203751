/*global define */

/*
* TODO: move getCookie, setCookie, eraseCookie out of window global space
* FIXME: move getCookie, setCookie, eraseCookie out of window global space
*/

/**
 * # Summary
 * List of functions to read and write cookies
 *
 * @module libs/plugins/jquery.cookie
 */
define(
	'jQueryCookie',[
		'jquery',
		'./browserFix'
	],
	function ($) {
		/**
		 * Function to return the value of a given cookie. If no parameter is given, return an associative list of all the cookies.
		 * @function $.getCookie
		 * @param {String} cookieName Cookie name (optional)
		 * @return {String|Object} Return the string value of the searched cookie or null if not found. If no cookie name is given, returns an associative list of all the cookies. {name: value}
		 *
		 * Example: an array of cookies
		 *
		 *     {
		 *         optus: 'action=nav_personal',
		 *         optus2: '...'
		 *     }
		 */
		window.getCookie = $.getCookie;
		if (!getCookie) {
			getCookie = function (cookieName) {
				var ret = {},
					dCookie = document.cookie;

				if ((dCookie.length) && (dCookie.charAt(0) === '?')) {
					// if the string starts by a '?'
					dCookie = dCookie.substr(1);
				}

				if (dCookie) { // Cookie is not empty
					var tokens = dCookie.split('; ');
					var tokensLength = tokens.length; //Array containg all cookies for this domain

					//number of cookies saved
					for (var i = 0, token, separatorIndex, paramName, paramVal; i < tokensLength; i++) { //Loop through cookies array
						token = tokens[i]; // Put cookie to be checked in a temp variable
						separatorIndex = token.indexOf('='); // get index of the '=' char

						// store param name and value
						if (separatorIndex !== -1) {
							paramName = token.substr(0, separatorIndex);
							paramVal = decodeURIComponent(token.substr(separatorIndex + 1));
						} else {
							paramName = token;
							paramVal = '';
						}

						if (cookieName === undefined) { // if there's no cookie name, store them all
							ret[paramName] = paramVal;
						} else if (paramName === cookieName) { // is it the searched cookie?
							return paramVal;
						}
					}
				}
				if (cookieName !== undefined) { // searched cookie not found
					return null;
				}
				return ret; // return all found cookies if no cookie name was given
			}; // end of function getCookie(cookieName)
			$.getCookie = getCookie;
		}
		/////////////////////////////////////////////////////////////////////////////////
		/**
		 * Function to set the cookie values when given a cookie name and value, other optinal parameters are accepted too.
		 * See also example 3 to see how to call this function with only one config object.
		 * @function $.setCookie
		 * @param {String} name Cookie name
		 * @param {String} value Cookie value - If the cookie value is undefined or null, it will delete it
		 * @param {Object} [optionalParams] Options
		 * @param {string|Date|number} [optionalParams.expires] Expiry date as a string, a Date object or number of seconds (before expiry). Give a past date or -1 to delete the cookie.
		 * @param {string} [optionalParams.path] Path where the cookie resides
		 * @param {string} [optionalParams.domain] Cookie domain
		 * @param {bool} [optionalParams.secure] For secure domains only or all domains
		 * @return {void}
		 *
		 * @example
		 * setCookie('Optus', 'test', {expires: '12/02/2008', path: '/', domain: '', secure: false});
		 *
		 * @example
		 * setCookie('Optus', 'test2');
		 *
		 * @example
		 * setCookie({
		 *     name: 'cookie name',
		 *     value: 'cookie value',
		 *     expires: 'an expiry date' || number of seconds, // give a past date or -1 to delete the cookie
		 *     path: 'path where the cookie resides',
		 *     domain: 'cookie domain',
		 *     secure: 'for secure domains only or all domains'
		 * });
		 */
		window.setCookie = $.setCookie;
		if (!setCookie) {
			setCookie = function (name, value, optionalParams) {
				if ((arguments.length === 1) && (typeof(arguments[0]) === 'object')) { // if we only have a config object
					var config = name,
						tempName = config.name,
						tempValue = config.value;

					config.name = config.value = undefined;

					return setCookie.apply(this, [tempName, tempValue, config]);
				} else {
					var defaultParams = {
							// expires: // no need to define it for session cookies
							path: '/'
							// not needed = the code below creates interferences
							//domain: window.location.hostname,
							//secure: (window.location.protocol === 'https:')
						},
						params = {};

					// checking parameters
					if (optionalParams !== undefined) {
						if (typeof(optionalParams) !== 'object') {
							throw new Error('setCookie(): invalid optionalParams parameter.');
						}
					}

					$.extend(params, defaultParams, optionalParams || {});

					if ((name !== undefined) && (name + '').length) { // If we have a name
						var expires = params.expires,
							path = params.path,
							domain = params.domain,
							secure = params.secure ? '; secure' : '';

						// Disable JSHint check only for this line because we really want to check with '=='
						//noinspection JSHint
						if (value == null) { // delete the cookie when it has a value that is null or undefined
							expires = -1;
						}

						path = path ? '; path=' + path : '';
						domain = domain ? '; domain=' + encodeURIComponent(domain) : '';

						if (expires !== undefined) {
							if (typeof expires === 'number') { //if we have a number
								var current_date = new Date();
								// Get the current time and the time when you want the cookie to expire all in milliseconds
								current_date.setTime(current_date.getTime() + (expires * 1000));
								expires = current_date;
							}

							if (expires instanceof Date) { // we have a date
								expires = '; expires=' + expires.toGMTString();
							} else {
								throw new Error('setCookie(): invalid cookie expiration date type.');
							}
						} else {
							expires = '';
						}

						// Write newly created cookie
						document.cookie = encodeURIComponent(name) + "=" + encodeURIComponent(value) + expires + path + domain + secure;
					} else { // if a cookie name was not provided
						throw new Error('setCookie(): cookie name undefined.');
					}
				}
			}; // end of function setCookie(name, value, optionalParams)
			$.setCookie = setCookie;
		}
		////////////////////////////////////////////////////////////////////////////////////////////////////
		/**
		 * Erase a cookie identified by its name.
		 * If you provide a full cookie definition object (see setCookie()), then it will be able to delete it more accurately
		 *
		 * @function $.eraseCookie
		 * @param {String} cookieName Cookie name
		 * @param {Object} cookieName Cookie definition, to be able to delete a cookie of a particular path or domain
		 * @return {void}
		 */
		window.eraseCookie = $.eraseCookie;
		if (!eraseCookie) {
			eraseCookie = function (cookieName) {
				var cookieProperties = {};
				if (typeof(cookieName) === 'object') { // if cookieName is an object, that must be a specific cookie to delete
					cookieProperties = cookieName;
				} else { //If getCookie returned a value, which means it found the cookie
					cookieProperties.name = cookieName;
				}
				cookieProperties.value = null; // ensure that we delete the cookie
				setCookie(cookieName);
			}; // end of function eraseCookie(cookieName)
			$.eraseCookie = eraseCookie;
		}
	}
);


