/*global define */
/**
 * Small module to provide the requirejs config with the right URL file paths
 */
define(
	'core/setupRequireConfig',[
		'../modulesConfig' // this dependency is relative to the folder location of this current module
	],
	function (modulesConfig) {
			

		/**
		 *	Returns array if path has asset version number (eg "/opfiles/v200/")
		 */ 
		var isAssetVersionInPath = function(path) {
			return path.match(/\/opfiles\/v\d[0-9]*/) || {};
		};

		/**
		 *	Returns true if path is an external path
		 */
		var isPathExternal = function(path) {
			return (path.indexOf("//") === 0);
		};

		/**
		 *	Returns true if path has /opfiles
		 */
		var isOpFilesInPath = function(path) {
			return (path.indexOf('/opfiles') > -1);
		};

		/**
		 *	Returns require.baseUrl
		 */
		var getRequireJsBaseUrl = function() {
			return window.require.toUrl('');
		};

		/**
		 *	Returns true if pathPrefix is array with a length
		 */
		var isPathPrefixArray = function(pathPrefix) {
			return (pathPrefix instanceof Array) && (pathPrefix.length);
		};

		/**
	 	 *	@see http://stackoverflow.com/q/7616461/940217
		 *	@return {number}
	 	 */
		String.prototype.hashCode = function() {
			var hash = 0;
			if (this.length === 0) { 
				return hash; 
			}
	    	for (var i = 0; i < this.length; i++) {
        		var character  = this.charCodeAt(i);
        		hash = ((hash<<5)-hash)+character;
        		hash = hash & hash; // Convert to 32bit integer
	    	}
	    	return Math.abs(hash);
		};

		/**
		 *	Select prefix from urlPrefixArray based on path file length
		 */
		var generatePrefixFromPathLength = function(path, urlPrefixArray) {
			return urlPrefixArray[path.hashCode() % urlPrefixArray.length];
		};

		/**
		 *	Remove from /opfiles from the path url
		 */
		var removeOpFilesFromPath = function(path) {
			return path.split("/opfiles")[1];
		};

		/**
		 *	Remove from /opfiles/vxxx from the url
		 */
		var removeAssetVersionFromPath = function(path) {
			return path.split(isAssetVersionInPath(path))[1];
		};

		/**
		 *	Return path of module with prefix from prefixArray 
		 */
		var getPathPrefixedFromArray = function(path, urlPrefixArray, baseUrlOverride) {
			var selectedUrlPrefix = generatePrefixFromPathLength(path, urlPrefixArray),
				requireJsBaseUrl = getRequireJsBaseUrl();

			if (baseUrlOverride) {
				requireJsBaseUrl = baseUrlOverride;
			}

			// No opfiles in path url:
			// path = prefix + requireJsBaseUrl + path
			if (!isOpFilesInPath(path)) {
				if (isAssetVersionInPath(selectedUrlPrefix).length && isAssetVersionInPath(requireJsBaseUrl).length) {
					requireJsBaseUrl = removeAssetVersionFromPath(requireJsBaseUrl);
				} else if (isOpFilesInPath(selectedUrlPrefix) && isOpFilesInPath(requireJsBaseUrl)) {
					requireJsBaseUrl = removeOpFilesFromPath(requireJsBaseUrl);					
				}

				path = selectedUrlPrefix + requireJsBaseUrl + path;
			}

			// Path has asset version:
			// path = prefix + path
			else if (isAssetVersionInPath(path).length) {
				path = removeAssetVersionFromPath(path);
				path = selectedUrlPrefix + path;
			}

			// Path has only opfiles:
			// path = pre + path
			else if (isOpFilesInPath(path)) {
				path = removeOpFilesFromPath(path);
				path = selectedUrlPrefix + path;
			}

			return path;

		};

		return {
			/**
			 * List of all requirejs modules
			 * @param {String} pathPrefix the prefix we are adding to paths
			 * @param {String} baseUrlOverride allow us to override require.baseUrl when constructing urls
			 * @return {Object}
			 */
			paths: function (pathPrefix, baseUrlOverride) {
				var curPathConfig = {},
					paths = modulesConfig.paths;

				// Allow us to prefix the module paths
				if (pathPrefix) {

					for (var key in paths) {
						if (paths.hasOwnProperty(key) && paths[key]) {

							// dont prefer urls that start with "//" - those are external modules
							if (isPathExternal(paths[key])) {
								curPathConfig[key] = paths[key];
							} else {

								// we got given a list of prefixes, we will choose which one based on length of path
								// otherwise we just prepend the path with the prefix string
								if (isPathPrefixArray(pathPrefix)) {
									curPathConfig[key] = getPathPrefixedFromArray(paths[key], pathPrefix, baseUrlOverride);
								} else {
									curPathConfig[key] = pathPrefix + paths[key];
								}
							}
						}
					}

				} else {
					curPathConfig = paths;
				}
				
				return curPathConfig;
			},
			/**
			 * Define js module dependencies and exported variables if any
			 * @type {Object}
			 */
			shim: modulesConfig.shim,
			map: modulesConfig.map
		};
	}
);

