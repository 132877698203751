/*global require, define */
/**
 * Main app js loader
 * Will add all necessary js libraries and common js setup functions necessary BEFORE rendering the page BODY
 *
 */
define(
	'core/setupApp',[
		// Enable this if we want to make jQuery module non-global (i.e. not generally available
		// as window.$ or window.jQuery)
		// 'jqueryNoConflict',
		'jquery',
		'safeFirebug',
		'browserFix',
		'jQueryCookie',
		'underscore'
	],
	function () {
		'use strict';

		// load the rest of the main js libraries asynchronously because we don't want to block
		// the page rendition at the header
		$(document).ready(function () {

			// Only load redmarine if this page has not (yet) been flagged as a "lux page" in CMS.
			if (window.patternLibraryBaseUrl === void 0) {
				require(['widgetManager'],
					function (widgetManager) {
						widgetManager.init();
					}
				);
			}

			// YellowSubmarine era code.
			require(['mainPostload']);

		});
	}
);

