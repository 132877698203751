define('dispatcher',['underscore'], function(_) {

	/**
	*
	*	DISPATCHER
	*	==========
	*
	*	To dispatch an action from a widget, you can
	*	- Use javascript: widget.ractive.fire('dispatch.action', someData);
	*	- Use templating: <a on-click='dispatch.someAction:someData'></a>
	*
	*	To bind an action to a widget method,
	* 	simply add a 'dispatch table' with strings to local methods at the root level of your widget
	*	widget.actions = ['localMethod1', '...']
	*
	**/
	function Dispatcher () {

		var self = this;

		// Maintain a list of registered widget instances
		this.widgets = {};

		// Maintain a list of all actions..
		this.actions = {};

		// Maintain a history of dispatched actions
		this.dispatchedActions = [];

		// Register widget methods against action
		this.bindWidget = function(widget) {

			var widgetInstance = widget.instance;
			// Create unique id for each widget.
			var widgetName = [widget.name, widgetInstance.ractive._guid].join('-');
			this.widgets[widgetName] = widgetInstance;

			if(widgetInstance.actions) {
				_.forEach(widgetInstance.actions, function(methods, actionName) {

					if(!this.actions[actionName]) {
						this.actions[actionName] = {};
					}

					this.actions[actionName][widgetName] = methods;

				}, this);
			}

			// Bind Ractive Event proxy
			if(widgetInstance.ractive) {

				widgetInstance.ractive.on('dispatch.*', function() {

					// Handle arguments differenctly (thanks to Ractive event firing non-consistency..)
					var data = arguments.length === 1 ? arguments[0] : arguments[1];

					// Split our event name so that we can extract its action name
					var eventNameArray = this.event.name.split('.');

					// The action follows the dispatch keyword
					var actionName = eventNameArray[eventNameArray.indexOf('dispatch') + 1];

					// Dispatch
					self.dispatch(actionName, data);

				});

				if (widgetInstance.replayDispatchedActions) {
					self.doReplayDispatchedActions(widgetInstance, widgetName);
				}

			}

		};

		// Call relevant methods on a widget instance, passing data.
		this.callWidgetMethods = function (widgetInstance, methods, data) {
			_.forEach(methods, function(method) {
				widgetInstance[method](data);
			});			
		};

		// Dispatch an action to the registered widget' methods
		this.dispatch = function(actionName, data) {

			// Loop through widgets bound to this action, execute their methods.
			_.forEach(this.actions[actionName], function(methods, widgetName) {
				this.callWidgetMethods(this.widgets[widgetName], methods, data);
			}, this);

			// Push to an array used for testing and logging actions
			this.dispatchedActions.push({
				'actionName': actionName,
				'data': data
			});

			return this.dispatchedActions;

		};

		// replay dispatched actions for the registered widget' methods
		this.doReplayDispatchedActions = function(widgetInstance, widgetName) {
			_.forEach(this.dispatchedActions, function(action) {
				var methods = this.actions[action.actionName] ? this.actions[action.actionName][widgetName] : [];
				this.callWidgetMethods(widgetInstance, methods, action.data);
			}, this);
		};

	}

	// This enforces a singleton pattern, no need for AMD modules to hook into window.dispatcher!
	return new Dispatcher();

});

