/*global define, require */
/**
 * Main config file that will include requirejs "module" definitions.
 * Here, "module" refers to a javascript requirejs module.
 */
define(
	'config',[
		'core/setupRequireConfig'
	],
	function (setupRequireConfig) {
		'use strict';

		// Set the require.js configuration for your application.
		require.config({
			// enforceDefine: true, // necessary for IE. See http://requirejs.org/docs/api.html#ieloadfail

			/**
			 * The number of seconds to wait before giving up on loading a script. Setting it to 0 disables the timeout.
			 * The requirejs default value was 7 seconds but it's sometimes too slow for our test environments.
			 * @see http://requirejs.org/docs/api.html#config-waitSeconds
			 * @type {Number}
			 */
			waitSeconds: 60,

			map: setupRequireConfig.map,

			paths: setupRequireConfig.paths(window.urlPathPrefixes||""),

			shim: setupRequireConfig.shim
		});

		////////////////////////////////
		// @todo Create a core.js file for code that needs to be run immediately during page load (synchronous)
		////////////////////////////////

	}
);
