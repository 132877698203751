/*global require, define */
define(
	'widgetManager',[
		'jquery',
		'underscore',
		'dispatcher'
	],
	function ($, _, dispatcher) {

		return {

			paths: {
				base: '/opfiles/ys/redmarine',
				cdn: '//smb.optus.com.au'
			},

			cssClassWidgetInitialized: 'widget-initialized',

			init: function () {
				var self = this;

				self.getWidgetContainers().then(function(widgetList) {
					// You need value() because chains are lazily evaluated in lodash.
					widgetList.each(self.initWidget, self).value();
				});
			},

			getWidgetData: function (widgetContainer) {

				var self = this;

				var deferred = $.Deferred();

				var widgetData;
				var $scriptElem = $(widgetContainer).find('script[type="text/widgetData"]');
				var counter = 0;

				var widgetData = self.loadWidgetData($scriptElem);

				if (widgetData) {

					deferred.resolve(widgetData);

				} else {

					var interval = setInterval(function () {

						var widgetData = self.loadWidgetData($scriptElem);

						if (widgetData) {

							deferred.resolve(widgetData);
							clearInterval(interval);

						} else {

							// Keep going.
							counter++;

						}

						if (counter >= 4) {
							deferred.reject(widgetData);
							console.error('Failed loading JSON data from element after max tries.');
							clearInterval(interval);
						}

					}, 1000);
				}

				return deferred.promise();

			},

			loadWidgetData: function ($scriptElem) {

				var widgetDataString = $scriptElem.text().trim();
				var widgetData = this.parseJSON(_.unescape(widgetDataString));

				try {

					if (widgetData.serviceDataList) {
						return widgetData.serviceDataList[0] || widgetData.serviceDataList;
					}

					return widgetData;

				} catch (e) {

					console.error('serviceDataList is not found in widgetData', e, widgetData);
					return null;

				}

			},

			getWidgetContainers: function () {
				var def = $.Deferred(),
					interval;

				// since widget.hbs is being loaded asynchronously on legacy pages
				// We need to wait for .widget-container to be available
				interval = setInterval(function() {
					if ($('.widget-container').length > 0) {
						clearInterval(interval);
						def.resolve(
							_($('.widget-container').not('.' + this.cssClassWidgetInitialized))
						);
					}
				}, 1000);

				return def.promise();
			},

			initWidgetWithDataURL: function(widgetContainer, widgetDataURL) {

				var self = this;

				$.getJSON(widgetDataURL)
					.done(function(result) {
						self.initWidget(widgetContainer, result);
					})
					.fail(function() {
						throw new Error('Failed to load data for widget');
					});

			},

			initWidget: function (widgetContainer, widgetData) {

				var self = this;
				var promise;

				// Either use the passed widgetData (from initWidgetWithDataURL), or call getWidgetData().
				if (_.isEmpty(widgetData)) {
					promise = self.getWidgetData(widgetContainer);
				} else {
					// Simulate a promise to allow below code to be generic.
					var deferred = $.Deferred();
					deferred.resolve(widgetData);
					promise = deferred.promise();
				}

				// Wait for async data load, to get around IE JSON size issues and failing to parse JSON.
				promise.done(function (widgetData) {

					if (!widgetData || !widgetData.id) {
						console.error('incorrect structure for widgetData', widgetData);
						return;
					}

					self.loadCssForWidget(widgetData.id);

					var widgetBundleUrl = self.paths.base + '/compiled/widgets/' +
						widgetData.id + 'Bundle.js';

					widgetBundleUrl = self.resolveCdnUrl(widgetBundleUrl);

					// For dynamic rendering, if widget container doesn't contain a .widget-instance div
					if (!$(widgetContainer).find('.widget-instance').length) {
						$(widgetContainer).addClass(widgetData.id + 'Widget');
						$(widgetContainer).append('<div class=\'widget-instance\' />');
					}

					require([
						widgetBundleUrl,
						'RedmarinePartials'
					], function (widgetBundle, RedmarinePartials) {

						// Below require block will not cause an additional HTTP request
						// as the widgetData.id has been silently declared as part of widgetBundle
						// More info on widget bundles: https://wiki.express.optus.com.au/x/LoMjB

						require([widgetData.id], function (widget) {

							window.RedmarineTemplates = window.RedmarineTemplates || {};
							_.extend(window.RedmarineTemplates, widgetBundle.templates);

							// Construct our widget object
							var RactiveWidget = {
								name: widgetBundle.name,
								instance: widget.initRactiveWidget({
									el: $(widgetContainer).find('.widget-instance'),
									data: widgetData,
									template: function (data) {
										var newTemplate = window.RedmarineTemplates[data.viewName];
										if (!newTemplate) {
											console.error('Data: ', data);
											throw new Error('Could not find the requested template. (' +
												data.viewName + ')'
											);
										}
										return newTemplate;
									},
									partials: RedmarinePartials
								})
							};

							// Bind our widget to the dispatcher
							dispatcher.bindWidget(RactiveWidget);

							window.dispatcher = dispatcher;
							window.RactiveWidgets = window.RactiveWidgets || [];
							window.RactiveWidgets.push(RactiveWidget);

							$(widgetContainer)
								.addClass([
									self.cssClassWidgetInitialized,
									widgetData.id + 'Widget'
								].join(' '));

						});

					});

				});

			},

			loadCssForWidget: function (widgetName) {
				var i,
					url,
					self = this,
					stylesCount,
					existingStyles = document.getElementsByTagName('link');

				// TODO: this path should link to compiled css files folder
				url = self.paths.base + '/widgets/' + widgetName + '/stylesheets/' + widgetName + '.css';

				url = self.resolveCdnUrl(url);

				for (i = 0, stylesCount = existingStyles.length; i < stylesCount; i++) {
					var curStyle = existingStyles[i];
					if (curStyle.getAttribute('rel') === 'stylesheet' && curStyle.getAttribute('href') === url) {
						break;
					}
				}

				if (i === stylesCount) { // stylesheet is not included yet, so add it
					var customStyles = document.createElement('link');
					customStyles.setAttribute('rel', 'stylesheet');
					customStyles.setAttribute('href', url);
					document.getElementsByTagName('head')[0].appendChild(customStyles);
				}
			},

			parseJSON: function (jsonString) {
				try {
					// http://stackoverflow.com/a/20392392
					var o = JSON.parse(jsonString);
					if (o && typeof o === 'object' && o !== null) {
						return o;
					}
				}
				catch (e) {
					console.error('Failed to parse JSON', e, jsonString);
				}
				return false;
			},

			/**
			 * Resolves the url for loading files, js or css using the cdn for production otherwise load the files
			 * using the relative url.
			 *
			 * @param url
			 * @returns {*}
			 */
			resolveCdnUrl: function(url) {
				var cdnUrl = url;

				// Let's detect what environment we are running in.  We are basically checking if we are in
				// production.  Our lower environments will use sit1.www.optus.com.au, uat.www.optus.com.au
				// which means we will use relative URL for loading widget assets (JS and CSS).
				if (window.location.host.indexOf('www.optus.com.au') === 0) {
					cdnUrl = this.paths.cdn + cdnUrl;
				}

				return cdnUrl;
			}
		};
	});

