/*global define */
/**
 * Main requirejs module definition file.
 * It lists almost all requirejs modules used by the frontend layer.
 * Note that those relative paths are based on the folder of this current file.
 * You have the ability to customise them to another root path with the .paths() method.
 *
 * Try to define as many dependencies and exports in the 'shim' definition to facilitate Internet Explorer debugging.
 */
define('modulesConfig',[],function () {

	'use strict';

	/**
	 *	List of external paths we will be using below in the modules paths.
	 *	These are paths that shouldn't be set as relative paths to this JS file.
	 */
	var externalPaths = function() {

		var onlineStyleGuidePath = '/opfiles/ys/online-style',
			onlineStyleGuideJsPath = onlineStyleGuidePath + '/javascripts/minified',
			customerCentrePath = '/opfiles/cc';	// customer center

		if (typeof window !== 'undefined') {
			if (window.onlineStyleGuide && window.onlineStyleGuide.path) {
				onlineStyleGuidePath = window.onlineStyleGuide.path;
			}
			if (window.onlineStyleGuide && window.onlineStyleGuide.jsPath) {
				onlineStyleGuideJsPath = window.onlineStyleGuide.jsPath;
			}
		}

		return {
			onlineStyleGuide: onlineStyleGuidePath,
			onlineStyleGuideJs: onlineStyleGuideJsPath,
			customerCentre: customerCentrePath
		};
	};

	var redmarineBasePath = '../../../../ys/redmarine';

	return {
		/**
		 * List of all requirejs modules; paths are relative to this file location
		 * @param {String} pathPrefix
		 * @return {Object}
		 */
		paths: {
			// Vendor libraries and plugins
			jquery: '../libs/vendors/jquery/jquery',
			jquerymobile: '../libs/vendors/jquery/jquery.mobile-1.3.1',
			jqueryNoConflict: '../libs/plugins/jquery.noConflict',
			jqueryVersionAdapter: '../libs/plugins/jquery.version.adapter',
			jqueryValidate: externalPaths().customerCentre + '/static/assets/common/js/externs/jquery.validate',
			jqueryValidateAdditional: externalPaths().customerCentre + '/static/assets/common/js/' +
				'jquery.validate.methods',
			jqueryMetadata: '/opfiles/cc/static/assets/common/js/externs/jquery.metadata',
			ModuleManager: 'core/ModuleManager',
			underscore: '../libs/vendors/lodash.underscore/lodash.underscore',
			underscoreString: '../libs/vendors/underscore/underscore-string',
			modernizr: '../libs/vendors/modernizr',
			backbone: '../libs/vendors/backbone/backbone',
			handlebars: '../libs/vendors/handlebars/handlebars',
			bowser: '../libs/vendors/bowser',
			BackboneValidation: '../libs/vendors/backbone/backbone-validation',

			'segmentio.analytics': '../libs/vendors/segmentio/analytics',
			analytics: 'core/setupAnalytics',
			'omniture': '../libs/vendors/segmentio/omniture_uncustomized',
			'analytics.omnitureMappings': '../libs/vendors/segmentio/ocaOmnitureMappings',
			'dictionary': 'core/dictionary',

			'clickByHashValue': '../../../../../../onePortal/opfiles/OnePortal/lib/js_min/plugins/jquery/app/' +
				'clickByHashValue',

			rivets: '../libs/vendors/rivets',
			text: '../libs/vendors/require/text',
			moment: '../libs/vendors/moment.2.7.0',
			momentTimezoneWithData: '../libs/vendors/moment-timezone-with-data',
			'jquery.urlParams': '../libs/plugins/jquery.urlParams',
			'jquery.placeholder': '../libs/vendors/jquery/jquery.placeholder',
			'jquery.honeypot': '../libs/plugins/jquery.honeypot',
			'jquery.popup': '../libs/plugins/jquery.popup',
			browserFix: '../libs/plugins/browserFix',
			// Foundation js
			foundation: '../libs/vendors/foundation/foundation',
			'foundation.alerts': '../libs/vendors/foundation/foundation.alerts',
			'foundation.clearing': '../libs/vendors/foundation/foundation.clearing',
			'foundation.cookie': '../libs/vendors/foundation/foundation.cookie',
			'foundation.dropdown': '../libs/vendors/foundation/foundation.dropdown',
			'foundation.forms': '../libs/vendors/foundation/foundation.forms',
			'foundation.interchange': '../libs/vendors/foundation/foundation.interchange',
			'foundation.joyride': '../libs/vendors/foundation/foundation.joyride',
			'foundation.magellan': '../libs/vendors/foundation/foundation.magellan',
			'foundation.orbit': '../libs/vendors/foundation/foundation.orbit',
			'foundation.placeholder': '../libs/vendors/foundation/foundation.placeholder',
			'foundation.reveal': '../libs/vendors/foundation/foundation.reveal',
			'foundation.section': '../libs/vendors/foundation/foundation.section',
			'foundation.tooltips': '../libs/vendors/foundation/foundation.tooltips',
			'foundation.topbar': '../libs/vendors/foundation/foundation.topbar',
			'foundation.abide': '../libs/vendors/foundation/foundation.abide',
			// jQuery UI
            'jquery.effects.blind': '../libs/vendors/jquery/ui/jquery.effects.blind',
            'jquery.effects.bounce': '../libs/vendors/jquery/ui/jquery.effects.bounce',
            'jquery.effects.clip': '../libs/vendors/jquery/ui/jquery.effects.clip',
            'jquery.effects.core': '../libs/vendors/jquery/ui/jquery.effects.core',
            'jquery.effects.drop': '../libs/vendors/jquery/ui/jquery.effects.drop',
            'jquery.effects.explode': '../libs/vendors/jquery/ui/jquery.effects.explode',
            'jquery.effects.fade': '../libs/vendors/jquery/ui/jquery.effects.fade',
            'jquery.effects.fold': '../libs/vendors/jquery/ui/jquery.effects.fold',
            'jquery.effects.highlight': '../libs/vendors/jquery/ui/jquery.effects.highlight',
            'jquery.effects.pulsate': '../libs/vendors/jquery/ui/jquery.effects.pulsate',
            'jquery.effects.scale': '../libs/vendors/jquery/ui/jquery.effects.scale',
            'jquery.effects.shake': '../libs/vendors/jquery/ui/jquery.effects.shake',
            'jquery.effects.slide': '../libs/vendors/jquery/ui/jquery.effects.slide',
            'jquery.effects.transfer': '../libs/vendors/jquery/ui/jquery.effects.transfer',
            'jquery.ui.accordion': '../libs/vendors/jquery/ui/jquery.ui.accordion',
            'jquery.ui.autocomplete': '../libs/vendors/jquery/ui/jquery.ui.autocomplete',
            'jquery.ui.button': '../libs/vendors/jquery/ui/jquery.ui.button',
            'jquery.ui.core': '../libs/vendors/jquery/ui/jquery.ui.core',
            'jquery.ui.datepicker': '../libs/vendors/jquery/ui/jquery.ui.datepicker',
            'jquery.ui.dialog': '../libs/vendors/jquery/ui/jquery.ui.dialog',
            'jquery.ui.draggable': '../libs/vendors/jquery/ui/jquery.ui.draggable',
            'jquery.ui.droppable': '../libs/vendors/jquery/ui/jquery.ui.droppable',
            'jquery.ui.mouse': '../libs/vendors/jquery/ui/jquery.ui.mouse',
            'jquery.ui.position': '../libs/vendors/jquery/ui/jquery.ui.position',
            'jquery.ui.progressbar': '../libs/vendors/jquery/ui/jquery.ui.progressbar',
            'jquery.ui.resizable': '../libs/vendors/jquery/ui/jquery.ui.resizable',
            'jquery.ui.selectable': '../libs/vendors/jquery/ui/jquery.ui.selectable',
            'jquery.ui.slider': '../libs/vendors/jquery/ui/jquery.ui.slider',
            'jquery.ui.sortable': '../libs/vendors/jquery/ui/jquery.ui.sortable',
            'jquery.ui.tabs': '../libs/vendors/jquery/ui/jquery.ui.tabs',
            'jquery.ui.widget': '../libs/vendors/jquery/ui/jquery.ui.widget',
            'jquery.ui.menu':'../libs/vendors/jquery/ui/jquery.ui.menu',

			// Base Backbone framework
			BackboneBase: 'backboneBase',
			BaseClassGenerator: 'core/BaseClassGenerator',
			BaseClass: 'core/BaseClass',
			BaseModel: 'models/BaseModel',
			BaseErrorModel: 'models/BaseErrorModel',
			BaseCollection: 'collections/BaseCollection',
			BaseErrorModelCollection: 'collections/BaseErrorModelCollection',
			BaseView: 'views/BaseView',
			BaseSettingsView: 'views/BaseSettingsView',
			BaseController : 'controllers/BaseController',
			BaseServiceModel: 'models/BaseServiceModel',
			BaseServiceModelCollection: 'collections/BaseServiceModelCollection',
			BaseTransferModel: 'models/BaseTransferModel',
			BaseTransferModelCollection: 'collections/BaseTransferModelCollection',
			BaseModuleModel: 'models/BaseModuleModel',
			BaseControllerModel: 'models/BaseControllerModel',
			BaseSettingsController: 'controllers/BaseSettingsController',
			ControllerEvents: 'controllers/ControllerEvents',

			// App plugins
			safeFirebug: '../libs/plugins/safeFirebug',
			underscoreToBoolean: '../libs/plugins/underscore.toBoolean',
			underscoreWithMixins: '../libs/plugins/underscore.mixins',
			jQueryBreakIfEmpty: '../libs/plugins/jquery.breakIfEmpty',
			jQueryReadOnlyInputs: '../libs/plugins/jquery.readOnlyInputs',
			jsonClone: '../libs/plugins/jsonClone',
			jsHashes: '../libs/plugins/jsHashes',
            jQueryCookie: '../libs/plugins/jquery.cookie',
            ajaxResponseHandler: '../libs/plugins/ajaxResponseHandler',

			// Some application-related modules
			setupRivets: 'core/setupRivets',
			setupBackboneValidation: 'core/setupBackboneValidation',
			componentPostLoader: 'core/componentPostLoader',
			imagePostLoader: 'core/imagePostLoader',
            helper: '../helpers',
			constants: '../constants',

			//SQ Tool
			SqToolView: 'views/SqToolView',
			SqToolController: 'controllers/SqToolController',
			SqToolServiceModel: 'models/SqToolServiceModel',
			SqToolModel: 'models/SqToolModel',
			SQResultPlanModel: 'models/SQResultPlanModel',

			// Postpaid Mobile Roaming settings for Mobile
			PostpaidMobileRoamingSettingsController: 'controllers/PostpaidMobileRoamingSettingsController',
			PostpaidMobileRoamingSettingsView: 'views/PostpaidMobileRoamingSettingsView',
			PostpaidMobileRoamingSettingsServiceModel: 'models/PostpaidMobileRoamingSettingsServiceModel',

			// Postpaid Mobile Roaming settings for Web
			PostpaidMobileRoamingSettingsWebController: 'controllers/PostpaidMobileRoamingSettingsWebController',
			PostpaidMobileRoamingSettingsWebView: 'views/PostpaidMobileRoamingSettingsWebView',

			// Postpaid Roaming Usage Estiamte
			PostpaidRoamingUsageController:'controllers/roamingUsage/PostpaidRoamingUsageController',
			PostpaidRoamingUsageView:'views/roamingUsage/PostpaidRoamingUsageView',
			PostpaidRoamingUsageServiceModel:'models/roamingUsage/PostpaidRoamingUsageServiceModel',
			PostpaidRoamingUsageDetailsServiceModel:'models/roamingUsage/PostpaidRoamingUsageDetailsServiceModel',
			PostpaidRoamingUsageCollection:'collections/roamingUsage/PostpaidRoamingUsageCollection',

			//Change plan
			ChangePlanController: 'controllers/ChangePlanController',
			ChangePlanView: 'views/ChangePlanView',
			PlanModel: 'models/PlanModel',
			MessageBoxModel: 'models/MessageBoxModel',
			ChangePlanServiceModel: 'models/ChangePlanServiceModel',

			//Extra data usage
			ExtraDataUsageController: 'controllers/ExtraDataUsageController',
			ExtraDataUsageView: 'views/ExtraDataUsageView',
			ExtraDataUsageServiceModel: 'models/ExtraDataUsageServiceModel',

			// global header search module
			GlobalHeaderSearchController: 'controllers/GlobalHeaderSearchController',
			GlobalHeaderSearchView: 'views/GlobalHeader/GlobalHeaderSearchView',
			GlobalHeaderSearchRecommendedModel: 'models/GlobalHeaderSearchRecommendedModel',
			GlobalHeaderSearchRecommendedCollection: 'collections/GlobalHeaderSearchRecommendedCollection',

			// help and support search module
			HelpAndSupportSearchController: 'controllers/HelpAndSupportSearchController',

			HelpAndSupportSearchView: 'views/HelpAndSupportSearchView',

			HelpAndSupportSearchModel: 'models/HelpAndSupportSearchModel',
			HelpAndSupportSearchResultSuggestionModel: 'models/HelpAndSupportSearchResultSuggestionModel',
			HelpAndSupportSearchResultSuggestionMetaModel: 'models/HelpAndSupportSearchResultSuggestionMetaModel',

			HelpAndSupportSearchCollection: 'collections/HelpAndSupportSearchCollection',

			// help and support decision trees
			HelpAndSupportDecisionTrees: '../libs/plugins/decisionTrees',

			ServiceSelectorController: 'controllers/myAccountDashboard/ServiceSelectorController',
            ServiceSelectorControllerHelper: 'controllers/myAccountDashboard/ServiceSelectorControllerHelper',
			ocaLoader: 'controllers/myAccountDashboard/oca/oca.loader',
			ocaHandler: 'controllers/myAccountDashboard/oca/oca.handler',
			ServiceFilteringView: 'views/myAccountDashboard/ServiceFilteringView',
			ServiceSearchFilteringView: 'views/myAccountDashboard/ServiceSearchFilteringView',
			ServiceTypeFilteringView: 'views/myAccountDashboard/ServiceTypeFilteringView',
			ServiceSelectorView: 'views/myAccountDashboard/ServiceSelectorView',
			ServiceContentView: 'views/myAccountDashboard/ServiceContentView',
			ServiceModel: 'models/myAccountDashboard/ServiceModel',
			ServiceTypeModel: 'models/myAccountDashboard/ServiceTypeModel',
			ServiceCollection: 'collections/myAccountDashboard/ServiceCollection',
			ServiceSearchCollection: 'collections/myAccountDashboard/ServiceSearchCollection',
			ServiceTypeCollection: 'collections/myAccountDashboard/ServiceTypeCollection',
			ServiceCampaignModel: 'models/myAccountDashboard/ServiceCampaignModel',

			// Billing Summary Module
			BillingSummaryController: 'controllers/myAccountDashboard/BillingSummaryController',
			BillingSummaryCollection: 'collections/myAccountDashboard/BillingSummaryCollection',
			BillingSummaryMetaModel: 'models/myAccountDashboard/BillingSummaryMetaModel',
			BillingSummaryModel: 'models/myAccountDashboard/BillingSummaryModel',
			BillingSummaryView: 'views/myAccountDashboard/BillingSummaryView',

			//usage summary module
			UsageSummaryTableController: 'controllers/usageSummary/UsageSummaryTableController',
			UsageSummaryTableServiceModel: 'models/usageSummary/UsageSummaryTableServiceModel',
			UsageSummaryTableDetailsServiceModel: 'models/usageSummary/UsageSummaryTableDetailsServiceModel',
			UsageSummaryTableView: 'views/usageSummary/UsageSummaryTableView',
			UsageSummaryTableCollection: 'collections/usageSummary/UsageSummaryTableCollection',
            UsageSummaryGraphServiceModel: 'models/usageSummary/UsageSummaryGraphServiceModel',
            UsageSummaryGraphCollection: 'collections/usageSummary/UsageSummaryGraphCollection',
            UsageSummaryGraphController: 'controllers/usageSummary/UsageSummaryGraphController',
            UsageSummaryGraphView: 'views/usageSummary/UsageSummaryGraphView',

            UsageSummaryTieredMetersModel: 'models/usageSummary/UsageSummaryTieredMetersModel',
            UsageSummaryBreakdownModel: 'models/usageSummary/UsageSummaryBreakdownModel',
            UsageSummaryServiceDataMetersModel: 'models/usageSummary/UsageSummaryServiceDataMetersModel',
            UsageSummaryHeaderModel: 'models/usageSummary/UsageSummaryHeaderModel',
            UsageSummaryTieredServiceGraphModel: 'models/usageSummary/UsageSummaryTieredServiceGraphModel',
            UsageSummaryTieredGraphCollection: 'collections/usageSummary/UsageSummaryTieredGraphCollection',
            UsageSummaryTieredGraphController: 'controllers/usageSummary/UsageSummaryTieredGraphController',
            UsageSummaryTieredGraphView: 'views/usageSummary/UsageSummaryTieredGraphView',


			UsageSummaryHistoryServiceModel: 'models/usageSummary/UsageSummaryHistoryServiceModel',
			UsageSummaryHistoryCollection: 'collections/usageSummary/UsageSummaryHistoryCollection',
			UsageSummaryHistoryController: 'controllers/usageSummary/UsageSummaryHistoryController',
			UsageSummaryHistoryView: 'views/usageSummary/UsageSummaryHistoryView',


			//Usage Details
			UsageDetailsServiceModel: 'models/usageDetails/UsageDetailsServiceModel',
			UsageDetailsResultsServiceModel: 'models/usageDetails/UsageDetailsResultsServiceModel',
			UsagePaginationModel : 'models/usageDetails/UsagePaginationModel',
			UsageDetailsCollection: 'collections/usageDetails/UsageDetailsCollection',
			UsagePaginationCollection : 'collections/usageDetails/UsagePaginationCollection',
			UsageDetailsController : 'controllers/usageDetails/UsageDetailsController',
			UsageDetailsView : 'views/usageDetails/UsageDetailsView',
			UsageDetailsModuleModel : 'models/usageDetails/UsageDetailsModuleModel',

			UsageDetailsVoipServiceModel: 'models/usageDetails/UsageDetailsVoipServiceModel',
			UsageDetailsVoipResultsServiceModel: 'models/usageDetails/UsageDetailsVoipResultsServiceModel',
			UsageDetailsVoipCollection: 'collections/usageDetails/UsageDetailsVoipCollection',
			UsageDetailsVoipController : 'controllers/usageDetails/UsageDetailsVoipController',
			UsageDetailsVoipView : 'views/usageDetails/UsageDetailsVoipView',
			UsageDetailsVoipDateResultsServiceModel: 'models/usageDetails/UsageDetailsVoipDateResultsServiceModel',
			UsageDetailsVoipDateModel: 'models/usageDetails/UsageDetailsVoipDateModel',
			UsageDetailsVoipDateCollection: 'collections/usageDetails/UsageDetailsVoipDateCollection',

			UsageDetailsExcessServiceModel: 'models/usageDetails/UsageDetailsExcessServiceModel',
			UsageDetailsExcessResultsServiceModel: 'models/usageDetails/UsageDetailsExcessResultsServiceModel',
			UsageDetailsExcessController : 'controllers/usageDetails/UsageDetailsExcessController',
			UsageDetailsExcessView : 'views/usageDetails/UsageDetailsExcessView',

			UsageDetailsPeakOffPeakModel: 'models/usageDetails/UsageDetailsPeakOffPeakModel',
			UsageDetailsPeakOffPeakResultsModel: 'models/usageDetails/UsageDetailsPeakOffPeakResultsModel',
			UsageDetailsPeakOffPeakController : 'controllers/usageDetails/UsageDetailsPeakOffPeakController',
			UsageDetailsPeakOffPeakView : 'views/usageDetails/UsageDetailsPeakOffPeakView',


			UsageDetailsUnlimitedServiceModel: 'models/usageDetails/UsageDetailsUnlimitedServiceModel',
			UsageDetailsUnlimitedResultsServiceModel: 'models/usageDetails/UsageDetailsUnlimitedResultsServiceModel',
			UsageDetailsUnlimitedController : 'controllers/usageDetails/UsageDetailsUnlimitedController',
			UsageDetailsUnlimitedView : 'views/usageDetails/UsageDetailsUnlimitedView',

			//Roaming Bolton Purchase Module
			RoamingBoltOnPurchaseController:'controllers/RoamingBoltOnPurchaseController',
			RoamingBoltOnStartPurchaseServiceModel:'models/roamingBolton/RoamingBoltOnStartPurchaseServiceModel',
			RoamingBoltOnAndCountryServiceModel:'models/roamingBolton/RoamingBoltOnAndCountryServiceModel',
			RoamingBoltOnDatesServiceModel:'models/roamingBolton/RoamingBoltOnDatesServiceModel',
			RoamingBoltOnSubmitOrderServiceModel:'models/roamingBolton/RoamingBoltOnSubmitOrderServiceModel',
			RoamingBoltonPurchaseView: 'views/RoamingBoltonPurchaseView',
			//Prepaid Usage Summary - Prepaid Refresh
			PrepaidUsageSummaryController: 'controllers/prepaidUsageSummary/PrepaidUsageSummaryController',
			PrepaidUsageSummaryServiceModel: 'models/prepaidUsageSummary/PrepaidUsageSummaryServiceModel',
			PrepaidUsageSummaryView: 'views/prepaidUsageSummary/PrepaidUsageSummaryView',
			PrepaidUsageSummaryServiceModelHelper: 'models/prepaidUsageSummary/PrepaidUsageSummaryServiceModelHelper',
			PrepaidUsageMetersModel: 'models/prepaidUsageSummary/PrepaidUsageMetersModel',
			PrepaidUsageTiersModel: 'models/prepaidUsageSummary/PrepaidUsageTiersModel',
			PrepaidUsagePartitionsModel: 'models/prepaidUsageSummary/PrepaidUsagePartitionsModel',

            // Payment Advice Module
            PaymentAdviceController: 'controllers/payments/PaymentAdviceController',
            PaymentAdviceModel: 'models/payments/PaymentAdviceModel',
            PaymentAdviceView: 'views/payments/PaymentAdviceView',

            // Payment Extension Module
            PaymentExtensionController: 'controllers/payments/PaymentExtensionController',
            PaymentExtensionModel: 'models/payments/PaymentExtensionModel',

			//Form module that submits to Form Emailer
			FormEmailerBaseView: 'views/FormEmailerBaseView',
			FormEmailerView: 'views/FormEmailerView',
			FormEmailerTwoColumnView: 'views/FormEmailerTwoColumnView',
			FormEmailerController: 'controllers/FormEmailerController',
			BusinessCaseStudyListServiceModel: 'models/business/CaseStudyListServiceModel',
			BusinessCaseStudiesListController: 'controllers/business/CaseStudiesListController',

			//Contract Breaker
			ContractBreakerRebateFormView: 'views/contractBreaker/contractBreakerRebateFormView',
			ContractBreakerRebateFormController: 'controllers/contractBreaker/contractBreakerRebateFormController',

			ArticlesByChannelController: 'controllers/articlesByChannel/ArticlesByChannelController',
			ArticlesByChannelModel: 'models/articlesByChannel/ArticlesByChannelModel',
			ArticlesByChannelCollection: 'collections/articlesByChannel/ArticlesByChannelCollection',
			ArticlesByChannelView: 'views/articlesByChannel/ArticlesByChannelView',

			GlobalHeaderNavMobile: externalPaths().onlineStyleGuideJs + '/app/nav/globalHeaderNavMobile',
			GlobalHeaderNavDesktop: externalPaths().onlineStyleGuideJs + '/app/nav/globalHeaderNavDesktop',
			GlobalHeaderNavMain: externalPaths().onlineStyleGuideJs + '/app/nav/globalHeaderNavMain',

			//Trade in
			TradeInController: 'controllers/tradeIn/TradeInController',
			TradeInDeviceView: 'views/tradeIn/TradeInDeviceView',
			TradeInConfirmationView: 'views/tradeIn/TradeInConfirmationView',
			TradeInDeviceModel: 'models/TradeInDeviceModel',
			TradeInDeviceCollection: 'collections/TradeInDeviceCollection',
			StreetTypeCollection: 'collections/tradeIn/streetType',
			CountryListCollection: 'collections/tradeIn/countryList',
			LicenceTypeCollection: 'collections/tradeIn/licenceType',
			StateListCollection: 'collections/tradeIn/stateList',

			//social icon helper controller
			SocialIconController: 'controllers/SocialIconController',
			SocialIconView: 'views/SocialIconView',

            MarketoForms: '//app-lon03.marketo.com/js/forms2/js/forms2',

		// News Feed Module
		NewsFeedController: 'controllers/NewsFeedController',
		NewsFeedCollection: 'collections/NewsFeedCollection',
		NewsFeedModel: 'models/newsFeeds/NewsFeedModel',
		NewsFeedListServiceModel: 'models/newsFeeds/NewsFeedListServiceModel',
		NewsFeedListView: 'views/NewsFeed/NewsFeedListView',

			SearchJobWidgetController: 'controllers/SearchJobWidgetController',
			SearchJobWidgetView: 'views/SearchJobWidgetView',

			Models: 'models',
			Controllers: 'controllers',
			Views: 'views',
			Collections: 'collections',
			Core: 'core',

			searchJobWidgetPath: '//careers.static.pageuppeople.com/Widgets/v2',

			// redmarine definitions for use inside YS's requirejs setup.
			Ractive: redmarineBasePath + '/vendors/ractive/ractive',
			// Need both as plugins require lowercase
			ractive: redmarineBasePath + '/vendors/ractive/ractive',
			RactiveValidate: redmarineBasePath + '/plugins/ractiveValidate',
			RactiveHelpers: redmarineBasePath + '/core/ractiveHelpers',
			ConfigStrings: redmarineBasePath + '/core/configStrings',
			Equalizer: redmarineBasePath + '/plugins/equalizer',
			bundlePriceCalculator: redmarineBasePath + '/libs/bundlePriceCalculator',
			serviceQuestionnaire: redmarineBasePath + '/libs/serviceQuestionnaire',
			searchUtils: redmarineBasePath + '/libs/searchUtils',
			rewardsRactiveValidate: redmarineBasePath + '/libs/rewardsRactiveValidate',
			Hammer: redmarineBasePath + '/vendors/hammerjs/hammer',
			IScroll: redmarineBasePath + '/vendors/iscroll/build/iscroll',

			widgetManager: redmarineBasePath + '/core/widgetManager',
            dispatcher: redmarineBasePath + '/core/dispatcher',
            carousel: redmarineBasePath + '/core/carousel',
			BaseWidget: redmarineBasePath + '/widgets/baseWidget/javascript/baseWidget',
			AidWidget: redmarineBasePath + '/widgets/aidWidget/javascript/aidWidget',

			RedmarinePartials: '' + redmarineBasePath + '/compiled/partials',

			// Include Redmarine custom plugins
			RedmarineTransition: redmarineBasePath + '/plugins/redmarineTransition',
			RedmarineDecorator: redmarineBasePath + '/plugins/redmarineDecorator',
			RactiveTap: redmarineBasePath + '/plugins/ractiveTap',
			'ractive-events-keys': redmarineBasePath + '/vendors/ractive-events-keys/dist/ractive-events-keys.min',

			RactiveTouch: redmarineBasePath + '/vendors/ractive-touch/index',

			// Include Redmarine Components
			RedmarineUXComponentTemplates: '' + redmarineBasePath + '/compiled/componentTemplates',
			RedmarineUXComponents: redmarineBasePath + '/compiled/components',

			simulant: redmarineBasePath + '/vendors/simulant/dist/simulant',

			// Include Legacy plugin - plugin converted to AMD, can be used in other frameworks
			RatingsAndReviews: '../../../../../../../opfiles/OnePortal/lib/js/plugins/ratingsAndReviewsAMD',
			pikaday: '../libs/vendors/pikaday'

		},
		/**
		 * Enable requirejs module mapping
		 */
		map: { // map: when we request a module, we map it to another one...
			'*': {
				// Enable this if we want to make jQuery module non-global (i.e. not generally available as
				// window.$ or window.jQuery)
				// 'jquery': 'jqueryNoConflict',
				rivets: 'setupRivets'
			},
			'setupRivets': {
				rivets: 'rivets'
			},
			'RactiveTouch': {
				ractive: 'Ractive',
				hammerjs: 'Hammer'
			}
			// Enable this if we want to make jQuery module non-global (i.e. not generally available as
			// window.$ or window.jQuery)
			// 'jqueryNoConflict': {
			//	'jquery': 'jquery'
			// }
		},
		/**
		 * Define js module dependencies and exported variables if any
		 * @type {Object}
		 */
		shim: {
			jqueryNoConflict: {
				deps: [
					'jquery'
				]
			},
			jquerymobile: {
				deps: [
					'jquery',
					'core/setupJqueryMobile'
				]
			},
			jqueryValidate: {
				deps: [
					'jquery'
				]
			},
			jqueryValidateAdditional: {
				deps: [
					'jqueryValidate'
				]
			},
			jqueryMetadata: {
				deps: [
					'jquery'
				]
			},
			modernizr: {
				exports: 'Modernizr'
			},
			analytics: {
				exports: 'analytics'
			},
			backbone: {
				deps: [
					'jquery',
					'underscore'
				],
				exports: 'Backbone',
				// Use the below init function because we need to make sure that Backbone uses only our internal
				// 'requirejs' version of jQuery
				init: function ($) {
					var env = this;
					env.Backbone.$ = $ || env.Zepto || env.ender || env.$;
					return env.Backbone;
				}
			},
			backboneNested: {
				deps: [
						'backbone'
					],
					exports: 'Backbone.NestedModel'

				},

			BackboneValidation: {
				deps: [
					'backbone',
					'underscore'
				],
				exports: 'Backbone.Validation'
			},
			BaseClassGenerator: {
				deps: [
					'jquery',
					'backbone',
					'BaseClass'
				]
			},
			'backbone-relational': {
				deps: [
					'backbone'
				]
			},
			rivets: {
				deps: [
					'backbone'
				],
				exports: 'rivets'
			},
			IScroll: {
				exports: 'IScroll'
			},
			'handlebars': {
				exports: 'Handlebars'
			},
			//////////
			// Foundation js shims
			//////////
			foundation: {
				deps: [
					'jquery'
				],
				exports: 'Foundation'
			},
			'foundation.alerts': {
				deps: [
					'foundation'
				]
			},
			'foundation.clearing': {
				deps: [
					'foundation'
				]
			},
			'foundation.cookie': {
				deps: [
					'foundation'
				]
			},
			'foundation.dropdown': {
				deps: [
					'foundation'
				]
			},
			'foundation.forms': {
				deps: [
					'foundation'
				]
			},
			'foundation.interchange': {
				deps: [
					'foundation'
				]
			},
			'foundation.joyride': {
				deps: [
					'foundation'
				]
			},
			'foundation.magellan': {
				deps: [
					'foundation'
				],
				exports: 'Foundation.libs.magellan'
			},
			'foundation.orbit': {
				deps: [
					'foundation'
				],
				exports: 'Foundation.libs.orbit'
			},
			'foundation.placeholder': {
				deps: [
					'foundation'
				],
				exports: 'Foundation.zj.fn.placeholder'
			},
			'foundation.reveal': {
				deps: [
					'foundation'
				],
				exports: 'Foundation.libs.reveal'
			},
			'foundation.section': {
				deps: [
					'foundation'
				],
				exports: 'Foundation.libs.section'
			},
			'foundation.tooltips': {
				deps: [
					'foundation'
				],
				exports: 'Foundation.libs.tooltips'
			},
			'foundation.topbar': {
				deps: [
					'foundation'
				],
				exports: 'Foundation.libs.topbar'
			},
			'foundation.abide': {
				deps: [
					'foundation'
				]
			},
			//////////
			// jQuery UI
			//////////
			'jquery.ui.core': {
				deps: [
					'jquery'
				],
				exports: 'jQuery.ui'
			},
			'jquery.ui.widget': {
				deps: [
					'jquery.ui.core'
				],
				exports: 'jQuery.widget'
			},
			'jquery.ui.accordion': {
				deps: [
					'jquery.ui.widget'
				],
				exports: 'jQuery.ui.accordion'
			},
			'jquery.ui.autocomplete': {
				deps: [
					'jquery.ui.position',
					'jquery.ui.menu'
				],
				exports: 'jQuery.ui.autocomplete'
			},
			'jquery.ui.button': {
				deps: [
					'jquery.ui.widget'
				],
				exports: 'jQuery.ui.button'
			},
			'jquery.ui.datepicker': {
				deps: [
					'jquery.ui.widget'
				],
				exports: 'jQuery.ui.datepicker'
			},
			'jquery.ui.dialog': {
				deps: [
					'jquery.ui.button',
					'jquery.ui.draggable',
					'jquery.ui.position',
					'jquery.ui.resizable'
				],
				exports: 'jQuery.ui.dialog'
			},
			'jquery.ui.draggable': {
				deps: [
					'jquery.ui.mouse'
				],
				exports: 'jQuery.ui.draggable'
			},
			'jquery.ui.droppable': {
				deps: [
					'jquery.ui.draggable'
				],
				exports: 'jQuery.ui.droppable'
			},
			'jquery.ui.effect': {
				deps: [
					'jquery'
				],
				exports: 'jQuery.effects'
			},
			'jquery.ui.effect-blind': {
				deps: [
					'jquery.ui.effect'
				],
				exports: 'jQuery.effects.blind'
			},
			'jquery.ui.effect-bounce': {
				deps: [
					'jquery.ui.effect'
				],
				exports: 'jQuery.effects.bounce'
			},
			'jquery.ui.effect-clip': {
				deps: [
					'jquery.ui.effect'
				],
				exports: 'jQuery.effects.clip'
			},
			'jquery.ui.effect-drop': {
				deps: [
					'jquery.ui.effect'
				],
				exports: 'jQuery.effects.drop'
			},
			'jquery.ui.effect-explode': {
				deps: [
					'jquery.ui.effect'
				],
				exports: 'jQuery.effects.explode'
			},
			'jquery.ui.effect-fade': {
				deps: [
					'jquery.ui.effect'
				],
				exports: 'jQuery.effects.fade'
			},
			'jquery.ui.effect-fold': {
				deps: [
					'jquery.ui.effect'
				],
				exports: 'jQuery.effects.fold'
			},
			'jquery.ui.effect-highlight': {
				deps: [
					'jquery.ui.effect'
				],
				exports: 'jQuery.effects.highlight'
			},
			'jquery.ui.effect-pulsate': {
				deps: [
					'jquery.ui.effect'
				],
				exports: 'jQuery.effects.pulsate'
			},
			'jquery.ui.effect-scale': {
				deps: [
					'jquery.ui.effect'
				],
				exports: 'jQuery.effects.scale'
			},
			'jquery.ui.effect-shake': {
				deps: [
					'jquery.ui.effect'
				],
				exports: 'jQuery.effects.shake'
			},
			'jquery.ui.effect-slide': {
				deps: [
					'jquery.ui.effect'
				],
				exports: 'jQuery.effects.slide'
			},
			'jquery.ui.effect-transfer': {
				deps: [
					'jquery.ui.effect'
				],
				exports: 'jQuery.effects.transfer'
			},
			'jquery.ui.menu': {
				deps: [
					'jquery.ui.widget',
					'jquery.ui.position'
				],
				exports: 'jQuery.ui.menu'
			},
			'jquery.ui.mouse': {
				deps: [
					'jquery.ui.widget'
				],
				exports: 'jQuery.ui.mouse'
			},
			'jquery.ui.position': {
				deps: [
					'jquery'
				],
				exports: 'jQuery.ui.position'
			},
			'jquery.ui.progressbar': {
				deps: [
					'jquery.ui.widget'
				],
				exports: 'jQuery.ui.progressbar'
			},
			'jquery.ui.resizable': {
				deps: [
					'jquery.ui.mouse'
				],
				exports: 'jQuery.ui.resizable'
			},
			'jquery.ui.selectable': {
				deps: [
					'jquery.ui.mouse'
				],
				exports: 'jQuery.ui.selectable'
			},
			'jquery.ui.slider': {
				deps: [
					'jquery.ui.mouse'
				],
				exports: 'jQuery.ui.slider'
			},
			'jquery.ui.sortable': {
				deps: [
					'jquery.ui.mouse'
				],
				exports: 'jQuery.ui.sortable'
			},
			'jquery.ui.spinner': {
				deps: [
					'jquery.ui.button'
				],
				exports: 'jQuery.ui.spinner'
			},
			'jquery.ui.tabs': {
				deps: [
					'jquery.ui.widget'
				],
				exports: 'jQuery.ui.tabs'
			},
			setupRivets: {
				deps: ['rivets', 'jquery'],
				exports: 'setupRivets'
			},
			underscoreToBoolean: {
				deps: ['underscore'],
				exports: '_.toBoolean'
			},
			jQueryBreakIfEmpty: {
				deps: ['jquery'],
				exports: '$.fn.breakIfEmpty'
			}
		}
	};
});

